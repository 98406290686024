.Background1{
    --background: linear-gradient(0deg, rgba(0, 0, 0, 10.936), rgba(0, 0, 0, 0.36)), url(https://images.unsplash.com/photo-1561835503-648c2f1169d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTI4fHxwdXJwbGUlMjBiYWNrZ3JvdW5kfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60);
    --bg-image-opacity: 1;
    position: relative;
    isolation: isolate;
    width: 100%;

}

.Background1::after{
    content: '';
    opacity: var(--bg-image-opacity);
    background: var(--background);
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -1;
    inset: 0;

}