.shadowSubscribe{
    background: #EDF2FF;
    box-shadow: 0px 4px 115px rgba(0, 0, 0, 0.25), 27px 22px 131px -3px rgba(0, 0, 0, 0.02);
    border-radius: 36px;
  }

  .textbox{
    font-family: "format";
  }

  .bg-gradient-radial {
    background-image: radial-gradient(89.96% 286.92% at 50.17% 231.78%, rgba(128, 159, 240, 0) 3.45%, rgba(215, 225, 252, 0.8) 30.35%, #EDF2FF 82.28%);
  }
  


.Back{
    --background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://images.unsplash.com/photo-1523973740062-18e700e8da35?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1856&q=80);
    --background-opacity: .9;
    position: relative;
    isolation: isolate;
    border-bottom: 0.5rem solid #3C3257;
}

.Back::after{
    content: '';
    opacity: var(--background-opacity);
    background: var(--background);
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -1;
    inset: 0;

}

