.back-img{
    --background: linear-gradient(101.83deg, rgba(196, 48, 107, 0.5) 19.46%, rgba(242, 123, 170, 0.5) 74.07%), url(https://images.unsplash.com/photo-1502679726485-931beda67f88?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1776&q=80);
    --bg-image-opacity: 1;
    position: relative;
    isolation: isolate;
    width: 100%;
    height: 100%;

}

.back-img::after{
    content: '';
    opacity: var(--bg-image-opacity);
    background: var(--background);
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -1;
    inset: 0;

}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}
.feedback{
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 14rem;
    gap: 0.5rem;
   
}

.Image{
    position: absolute;
    left: 0;
    width: 15vw;
    padding-top: 10rem;
    opacity: 80%;
}
.Image_1{
    position: absolute;
    width: 15vw;
    right: 0;
    padding-top: 15rem;
    opacity: 80%;
    transform: rotate(180deg);  
}
@media(max-width:2000px){
    .Image_1{
        width: 13vw;
       margin-top: 1rem;
       
    }
    .Image{
        width: 13vw;
        padding-top: 12rem;
    }
    
} 
@media(max-width:980px){ 
    .Image_1{
    margin-top: 0;
        width: 15vw;
        z-index: 0;
    }
    .Image{
        width: 15vw;
        margin-top: 8rem;
        z-index: 0;
    }

}    
.store{
    background-color: #000000;
    width: 100%;
    height: 70rem;
}

.Test-text{
    z-index: 2;
    position: relative;
    font-size: 3.5rem;
    padding: 0.41rem ;
    text-align: center;
    color: #ffff;
    font-family: "dosis";
}



.ecllip1{
    height: 4rem;
    width: 4rem;
    object-fit: cover;
    background-color: transparent;
    margin: 0 10px;
    /* background-image: url(../../Utils/images/Testimonial/fitness-1.jpg); */
    background-size: cover;
    object-fit: cover;
}
.ecllip2{
    
    /* border-radius: 50%;
    object-fit: cover;
    z-index: 2;
    position: relative;
    height: 6rem;
    width: 6rem;
    background-color: transparent;
    margin: 0 10px; */
    /* background-image: url(../../Utils/images/Testimonial/fitness-2.jpg); */
    /* background-size: cover;
    background-position: -8rem; */
    visibility: hidden;
    width: 0;
    margin: 0;
}


.Design_1{
    transform: rotate(181deg);
}


.ecllip3{
    object-fit: cover;
    background-size: cover;
    position: relative;
    z-index: 2;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background-color: transparent;
    margin:  0 10px;
    /* background-image: url(../../Utils/images/Testimonial/fitness-3.jpg); */
    
    
    
}
.ecllip4{
    /* border-radius: 50%;
    object-fit: cover;
    position: relative;
    width: 6rem;
    height: 6rem;
    background-color: transparent;
    margin: 0 10px;
    /* background-image: url(../../Utils/images/Testimonial/fitness-4.jpg); */
    /* background-size: cover;  */
    visibility: hidden;
    width: 0;
    margin: 0;
}
.ecllip5{
    object-fit: cover;
    width: 4rem;
    height: 4rem;
    background-color: transparent;
    margin: 0 10px;
    /* background-image: url(../../Utils/images/Testimonial/fitness-5.jpg); */
    background-size: cover;
}

.feedback-text{
    text-align: center;
    color: #ffff;
    padding: 0 15rem;
    font-size: rem;

}

.mona{
    position: relative;
    z-index: 2;
    color: #ffff;
    text-align: center;
    font-size: 1.5rem;
    padding: 1rem;
    left: 14rem;
    
}

.star{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
}

@media(max-width:1356px){

    .ecllip1,
    .ecllip2,
    .ecllip4,
    .ecllip5{
        visibility: hidden;
        width: 0;
        margin: 0;
    }

    .ecllip4{
        height: 6rem;
         width: 6rem; 
    }
    .ecllip2{
        height: 6rem;
         width: 6rem; 
    }
    .ecllip3{
        height: 10rem;
        width: 100rem; 

        
    }

   
    
    
}

@media(max-width:1300px){
    .Image{
        margin-top: 8rem;
    }
}
@media(max-width:1600px){
    .feedback{
        max-height:max-content;
        
    }
}

@media(max-width:691px){
    .Test-text{
        font-size:3.8rem;
        padding-bottom: 3rem;
        margin-bottom: 0;
    }
    .ecllip3{
        margin-bottom: 3rem;
        height: 12rem;
        width: 12rem;
        
    }
    .ecllip1,
    .ecllip2,
    .ecllip4,
    .ecllip5{
        visibility: hidden;
        width: 0;
        margin: 0;
    }
    .feedback{
         height:auto;
        padding: 0;
        margin: 0;
        /* margin: 2rem 0; */
    }
    .mona{
        font-size: 2.4rem;
        margin-top: 1rem;
        left: 0;
    }
    .Image_1{
        width: 21vw;
        z-index: 0;
    }
    .Image{
        width: 21vw;
        margin-top: 17rem;
        z-index: 0;
    }
    .Design{
        height: 18rem;
        margin: 2rem;
        margin-right: 5rem;
    }
    .Design_1{
        height: 20rem;
        margin: 2rem;
        margin-left: 3rem;
    }
    
    @media(max-width:406px){
        
    }
    @media(max-width:500px){
        .Test-text{
            font-size:2rem;
            padding-bottom: 2rem;
            margin-bottom: 0;
        }
        .feedback{
            height: 10rem;
        }
        .des{
            font-size: 0.6rem;
        }
        .ecllip3{
            height: 40vw;
            width: 40vw;
            margin: 0 auto;
            padding: 0;
    }
    .mona{
        font-size: 1rem;
        margin-top: 0;
        height: auto;
    }

    .Image_1{
        width: 21vw;
        z-index: 0;
    }
    .Image{
        width: 21vw;
        margin-top: 7rem;
        z-index: 0;
    }
    .Design{
        height: 9rem;
        margin-top: 1rem;
        margin-right: 4rem;
        margin-bottom: 0;
    }
    .Design_1{
        height: 9rem;
        margin-left: 2rem;
        margin-bottom: 0rem;
    }
    .size{
        height: 38rem;
    }
}
}
