.Background{
    --bg-image: url(https://images.pexels.com/photos/3527778/pexels-photo-3527778.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
    --bg-image-opacity: .9;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
    isolation: isolate;
    width: 100%;

}

.Background::after{
    content: '';
    opacity: var(--bg-image-opacity);
    background-image: var(--bg-image);
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -1;
    inset: 0;

}