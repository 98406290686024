.show{
    --background: linear-gradient(0deg, rgba(0, 0, 0, 10.936), rgba(0, 0, 0, 0.36)), url(https://cdn.pixabay.com/photo/2017/02/04/20/27/background-2038259_1280.jpg);
    --bg-image-opacity: 1;
    position: relative;
    isolation: isolate;
    width: 100%;

}

.show::after{
    content: '';
    opacity: var(--bg-image-opacity);
    background: var(--background);
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -1;
    inset: 0;

}