
.New{
    --background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),  url(https://images.unsplash.com/photo-1621976360623-004223992275?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2028&q=80);
    --bg-image-opacity: 0.9;
    position: relative;
    isolation: isolate;
    width: 100%;
    
}

.New::after{
    content: '';
    opacity: var(--bg-image-opacity);
    background: var(--background);
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: -1;
    inset: 0;

}

.capital{

    font-family: 'Format';
}



@media(max-width:600px){
    .Over{
        gap: 4rem;
    }
}


.blurimg{
  text-shadow: 2px 2px 0 black;
}


@media(max-width:2000px){
    .size1{
        bottom:-99px;
        width: 28vw;
    }
}

@media(max-width:1920px){
    .size1{
        bottom: 0%;
        width: 25vw;
    }
}
@media(max-width:1550px){
    .size1{
        width: 33vw;
    }
}



@media (min-width:2052px) and (max-width:2732px)
{
    .size1{
        bottom: -18%;
        width: 25vw;
    }

}
@media (min-width:2733px) and (max-width:3102px) 

{
    .size1{
        bottom: 1%;
        left: 54%;
        width: 15vw;
    }

}
@media (min-width:3103px) and (max-width:4102px) 

{
    .size1{
        bottom: 1%;
        left: 54%;
        width: 15vw;
    }

}

@media (min-width:4103px) and (max-width:5464px)
{
    .size1{
        bottom: -2%;
        left: 52%;
        width: 12vw;
    }
}