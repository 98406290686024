@tailwind base;
@tailwind components;
@tailwind utilities;

/* ::-webkit-scrollbar {
  width: 0;
} */

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sansita&display=swap");

/* Dosis and Inter  */
@import url('https://fonts.googleapis.com/css2?family=Dosis&family=Inter&display=swap');
@font-face {
  font-family: 'Format';
  src: url('./Utils/fonts/format-1452-master/format-1452-master/Format_1452.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Summer';
  src: url('./Utils/fonts/with-summer/With\ Summer\ -\ TTF.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}





.JustAnotherHand {
  font-family: "Just Another Hand", cursive;
}

.RussoOne {
  font-family: "Russo One";
}

.Sansita {
  font-family: "Sansita";
}

#close-arrow {
  transform: rotate(180deg);
}


.shadowSubscribe2{
  box-shadow: 20px 10px 20px  #86CB92;
}
.shadowSubscribe3{
  box-shadow: 20px 10px 20px  #3C3257;
}