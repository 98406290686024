
.filters {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }
  
  .filters.show1 {
    max-height: 500px;
  }
  