.Fussion{
    font-family: 'Summer';
    font-style: normal;
    margin-top: 0.8rem;
    font-weight: 400;
    font-size: 20px;
    text-shadow: 3px 4px 7px rgba(0, 0, 0, 0.69);
    color: #EA028A;
}

.Fussion1{
    font-family: 'Format';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    width: 80%;
/* or 150% */
    color: #FFFFFF;
    text-shadow: 2px 2px 0 black;
}
@media (max-width:800px){
    .Fussion1{
        font-size: 0.8rem;
    }
}

@media (max-width:690px) {
    .Fussion{
        padding-top: 12rem;
    }
    .Fussion1{
        padding: .8rem;
        font-size: 13px;
        width: 20rem;
    }
}

@media (max-width:410px) {
    .title{
    padding: 6rem 4rem 6rem 2rem;
    }
}